import { Global, css } from '@emotion/core';
import GTWalsheimLightWoff from '../../static/fonts/GTWalsheim-Light.woff';
import GTWalsheimRegularWoff from '../../static/fonts/GTWalsheim-Regular.woff';
import GTWalsheimMediumWoff from '../../static/fonts/GTWalsheim-Medium.woff';
import GTWalsheimBoldWoff from '../../static/fonts/GTWalsheim-Bold.woff';

import GTWalsheimLightWoff2 from '../../static/fonts/GTWalsheim-Light.woff2';
import GTWalsheimRegularWoff2 from '../../static/fonts/GTWalsheim-Regular.woff2';
import GTWalsheimMediumWoff2 from '../../static/fonts/GTWalsheim-Medium.woff2';
import GTWalsheimBoldWoff2 from '../../static/fonts/GTWalsheim-Bold.woff2';

import AdonisBoldItalicOtf from '../../static/fonts/Adonis-Bold-Italic.otf';
import AdonisBoldOtf from '../../static/fonts/Adonis-Bold.otf';
import AdonisItalicOtf from '../../static/fonts/Adonis-Italic.otf';
import AdonisRegularOtf from '../../static/fonts/Adonis-Regular.otf';

import GibsonBookOtf from '../../static/fonts/Gibson-Book.otf';
import GibsonBookItalicOtf from '../../static/fonts/Gibson-BookItalic.otf';
import GibsonBookMediumOtf from '../../static/fonts/Gibson-Medium.otf';
import GibsonBookMediumItalicOtf from '../../static/fonts/Gibson-MediumItalic.otf';
import GibsonBookSemiBoldOtf from '../../static/fonts/Gibson-SemiBold.otf';
import GibsonBookSemiBoldItalicOtf from '../../static/fonts/Gibson-SemiBoldItalic.otf';

import { COLORS } from '../ui/variables';

const GlobalStyles = () => (
  <Global
    styles={css`
      body,
      h2,
      h3,
      h4 {
        font-family: Gibson;
      }
      h1 {
        font-family: Adonis;
      }
      a {
        color: ${COLORS.ORANGE};
        text-decoration: none;
      }
      @font-face {
        font-family: GT Walsheim;
        src: local('GT Walsheim Light'), local('GTWalsheim-Light'),
          url(${GTWalsheimLightWoff2}) format('woff2'),
          url(${GTWalsheimLightWoff}) format('woff');
        font-weight: 300;
        font-style: normal;
        font-display: swap;
        font-display: fallback;
      }
      @font-face {
        font-family: GT Walsheim;
        src: local('GT Walsheim Regular'), local('GTWalsheim-Regular'),
          url(${GTWalsheimRegularWoff2}) format('woff2'),
          url(${GTWalsheimRegularWoff}) format('woff');
        font-weight: 400;
        font-style: normal;
        font-display: swap;
        font-display: fallback;
      }
      @font-face {
        font-family: GT Walsheim;
        src: local('GT Walsheim Medium'), local('GTWalsheim-Medium'),
          url(${GTWalsheimMediumWoff2}) format('woff2'),
          url(${GTWalsheimMediumWoff}) format('woff');
        font-weight: 600;
        font-style: normal;
        font-display: swap;
        font-display: fallback;
      }
      @font-face {
        font-family: GT Walsheim;
        src: local('GT Walsheim Bold'), local('GTWalsheim-Bold'),
          url(${GTWalsheimBoldWoff2}) format('woff2'),
          url(${GTWalsheimBoldWoff}) format('woff');
        font-weight: 700;
        font-style: normal;
        font-display: swap;
        font-display: fallback;
      }

      @font-face {
        font-family: Adonis;
        src: local('Adonis Regular'), local('Adonis-Regular'),
          url(${AdonisRegularOtf}) format('opentype');
        font-weight: 400;
        font-style: normal;
        font-display: swap;
        font-display: fallback;
      }
      @font-face {
        font-family: Adonis;
        src: local('Adonis Italic'), local('Adonis-Italic'),
          url(${AdonisItalicOtf}) format('opentype');
        font-weight: 400;
        font-style: italic;
        font-display: swap;
        font-display: fallback;
      }
      @font-face {
        font-family: Adonis;
        src: local('Adonis Bold'), local('Adonis-Bold'),
          url(${AdonisBoldOtf}) format('opentype');
        font-weight: 600;
        font-style: normal;
        font-display: swap;
        font-display: fallback;
      }
      @font-face {
        font-family: Adonis;
        src: local('Adonis Bold Italic'), local('Adonis-Bold-Italic'),
          url(${AdonisBoldItalicOtf}) format('opentype');
        font-weight: 600;
        font-style: italic;
        font-display: swap;
        font-display: fallback;
      }

      @font-face {
        font-family: Gibson;
        src: local('Gibson Book'), local('Gibson-Book'),
          url(${GibsonBookOtf}) format('opentype');
        font-weight: 300;
        font-style: normal;
        font-display: swap;
        font-display: fallback;
      }
      @font-face {
        font-family: Gibson;
        src: local('Gibson Book Italic'), local('Gibson-Book-Italic'),
          url(${GibsonBookItalicOtf}) format('opentype');
        font-weight: 300;
        font-style: italic;
        font-display: swap;
        font-display: fallback;
      }
      @font-face {
        font-family: Gibson;
        src: local('Gibson Medium'), local('Gibson-Medium'),
          url(${GibsonBookMediumOtf}) format('opentype');
        font-weight: 600;
        font-style: normal;
        font-display: swap;
        font-display: fallback;
      }
      @font-face {
        font-family: Gibson;
        src: local('Gibson Medium Italic'), local('Gibson-Medium-Italic'),
          url(${GibsonBookMediumItalicOtf}) format('opentype');
        font-weight: 600;
        font-style: italic;
        font-display: swap;
        font-display: fallback;
      }
      @font-face {
        font-family: Gibson;
        src: local('Gibson SemiBold'), local('Gibson-SemiBold'),
          url(${GibsonBookSemiBoldOtf}) format('opentype');
        font-weight: 700;
        font-style: normal;
        font-display: swap;
        font-display: fallback;
      }
      @font-face {
        font-family: Gibson;
        src: local('Gibson SemiBold Italic'), local('Gibson-SemiBold-Italic'),
          url(${GibsonBookSemiBoldItalicOtf}) format('opentype');
        font-weight: 700;
        font-style: italic;
        font-display: swap;
        font-display: fallback;
      }
    `}
  />
);

export default GlobalStyles;
